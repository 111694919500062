<template>
    <vx-card
        v-if="data"
        title="Recent Activity"
        collapse-action
        refresh-content-action
        @refresh="recentActivityFetch"
        :startCollapsed="true"
    >
        <vs-list>
            <vs-list-header
                icon-pack="feather"
                icon="icon-check-circle"
                title="Wills Approved"
                color="success"
            ></vs-list-header>
            <vs-list-item
                v-for="(will, index) in data.wills_approved"
                :key="index + 'will-1'"
                :title="will.user.details.full_name"
                :subtitle="formatDateAndTime(will.checked_date)"
            >
                <vs-button
                    type="flat"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    :to="{ name: 'will', params: { id: will.id } }"
                />
            </vs-list-item>

            <vs-list-header
                icon-pack="feather"
                icon="icon-check-circle"
                title="Will Checked"
                color="success"
            ></vs-list-header>
            <vs-list-item
                v-for="(will, index) in data.wills_checked"
                :key="index + 'will'"
                :title="will.user.details.full_name"
                :subtitle="formatDateAndTime(will.checked_pre_final_date)"
            >
                <vs-button
                    type="flat"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    :to="{ name: 'will', params: { id: will.id } }"
                />
            </vs-list-item>

            <vs-list-header
                icon-pack="feather"
                icon="icon-clipboard"
                title="Will Notes"
                color="success"
            ></vs-list-header>
            <vs-list-item
                v-for="(note, index) in data.willdocument_notes"
                :key="index + 'note'"
                :title="note.note.substring(0, 100)"
                :subtitle="
                    formatDateAndTime(note.updated_date) +
                    ' - ' +
                    note.will.user.details.full_name
                "
            >
                <vs-button
                    type="flat"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    :to="{ name: 'will', params: { id: note.will.id } }"
                />
            </vs-list-item>

            <vs-list-header
                icon-pack="feather"
                icon="icon-file-text"
                title="Will Manual Changes"
                color="success"
            ></vs-list-header>
            <vs-list-item
                v-for="(note, index) in data.willdocument_manual_changes"
                :key="index + 'change'"
                :title="note.note.substring(0, 100)"
                :subtitle="
                    formatDateAndTime(note.updated_date) +
                    ' - ' +
                    note.will.user.details.full_name
                "
            >
                <vs-button
                    type="flat"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    :to="{ name: 'will', params: { id: note.will.id } }"
                />
            </vs-list-item>

            <vs-list-header
                icon-pack="feather"
                icon="icon-printer"
                title="Will Printed"
                color="success"
            ></vs-list-header>
            <vs-list-item
                v-for="(will, index) in data.wills_printed"
                :key="index + 'will-5'"
                :title="will.user.details.full_name"
                :subtitle="formatDateAndTime(will.printed_date)"
            >
                <vs-button
                    type="flat"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    :to="{ name: 'will', params: { id: will.id } }"
                />
            </vs-list-item>
        </vs-list>
    </vx-card>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'RecentActivityStaffCard.vue',
    props: ['collapsed'],
    mixins: [textFormat],
    mounted() {
        this.recentActivityFetch()
    },
    data() {
        return {
            data: null,
        }
    },
    methods: {
        recentActivityFetch(card) {
            http.get('user_staff_recent_activity', {
                params: { id: this.$store.getters.user.id },
            })
                .then((response) => {
                    this.data = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
