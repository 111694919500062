<template>
    <div>
        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3 mb-base"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <vx-card
                            v-if="userData.data"
                            :title="
                                userData.data.details.full_name +
                                ' AKA ' +
                                userData.data.email
                            "
                            collapse-action
                            >Maybe view staff history here, like comments,
                            notes, recently checked, approved or printied
                        </vx-card>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <RecentActivityStaffCard
                            :collapsed="true"
                        ></RecentActivityStaffCard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
]

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
import { sectionDescriptions } from '@/mixins/sectionDescriptions'
import { willStringHelpers } from '@/mixins/willStringHelpers'

import RecentActivityStaffCard from './Cards/RecentActivity'

export default {
    name: 'UserView',
    mixins: [textFormat],
    props: [],
    components: {
        RecentActivityStaffCard,
    },
    created() {
        this.userDataFetch(this.$route.params.id)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.userDataFetch(this.$route.params.id)
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            userData: {
                data: null,
            },
            backPath: null,
        }
    },
    methods: {
        userDataFetch(user_id) {
            this.$vs.loading()
            http.get('user_staff_detail', { params: { id: user_id } })
                .then((response) => {
                    this.userData.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
    },
}
</script>
